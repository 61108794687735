body {
    background-color: #c6c7c9;
    padding: 0px;
    margin: 0px;
  }
  h1,
  h2,
  p,
  ul,
  li {
    font-family: sans-serif;
  }
  h1 {
    margin: 0px;
  }
  ul.header li {
    display: flex;
    list-style-type: none;
    margin: 0;
  }
  ul.header {
    padding: 0;
    border-radius: 5px;
    margin-block-start: 0px;
    margin-block-end: 0px;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 0px;
  }
  ul.header li a {
    font-weight: bold;
    color: #182634;
    font-size: 16px;
    text-decoration: none;
    padding: 4px;
    display: inline-block;
  }
  .content {
    background-color: #fff;
    padding: 20px;
    border-radius: 5px;
    color: #182634;
  }
  .content h2 {
    padding: 0;
    margin: 0;
  }
  .content li {
    margin-bottom: under;
  }
  .active {
    text-decoration: underline !important;
  }
  .disabled {
    pointer-events:none; 
    opacity:0.6;
  }
  img {
    border-radius: 5px;
  }
  .MenuButton {
    background: none;
    color: #fff;
    font-weight: bold;   
    font-family: sans-serif;
    font-size: 16px;
    position: absolute;
    right: 5px;
    display: inline-block;
    height: 36px;
    min-width: 128px;
    padding: 6px 16px;
    line-height: 1.42857143;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 0;
    border-radius: 5px;
    outline: 0;

  }

  .rankBar {
    height: 10px;
    width: 100%;
    background-color: none;
  }
  